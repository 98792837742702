<template>
    <!--  杂志封面展示 -->
  <div class="container">

        <div class="box"
          v-for="(p,i) in project_list" :key="i"
        >
          <div class="img">
            <el-image :src="p.src" alt=""></el-image>
          </div>
          <div class="content">
            <h3>{{p.title}}</h3>
            <p>{{p.desc}}</p>
          </div>
        </div>
        <!-- Tim这个词代表的不是一个人，而是一群热爱海洋浮游和底栖生物研究的志愿者，有着强烈的兴趣和持久的热爱 -->
        <!-- 他们可能来自不同的行业和领域，物理、数学、计算机、人工智能，但是都有着一个共同的目标，就是努力使用所有可以触及的手段，去尽可能揭示那些微小不为人所知，却又对未来影响巨大的那些科学问题。 -->
        <!-- I think this conversation will become the basic constitution of the future institute -->

  </div>  
</template>

<script>

export default {
  data() {
    return {
      project_list:[
        // {
        //   id: 1,
        //   name:'Survey of plankton community of the coastal environment of Shenzhen',
        //   title: 'Plankton Survey, 2021',
        //   desc: 'Survey of plankton community of the coastal environment of Shenzhen: Phytoplankton are the primary producer and the key oxygen producer in the aquatic environment. They form the basis of the food web; They are considered important proxy to environmental pollution; They play an important role in the geochemical cycles of important elements. This project focuses on the seasonal variation and spatial distribution of phytoplankton in the coastal environment of Shen Zhen. Sampling of phytoplankton will be conducted quarterly. Phytoplankton species will be observed under light microscope and the image data will be used to establish a training data set for automated identification by AI. A database of phytoplankton as well as their occurrence pattern will be analyzed and linked with environmental parameters, through which the project aims at (1) facilitate automated identification of phytoplankton specimens collected in Shen Zhen and (2) provide the foundation for future modeling of environmental events such as harmful algal bloom (3) establish the biodiversity and ecosystem health index of Shen Zhen coastal environment.',
        //   src: require("../assets/img/ysa/ysa-2021-face-s.jpg"),
        // },
        // {
        //   id: 2,
        //   name:'Study of phototaxis in the larvae of marine benthos',
        //   title: 'OA, Issue 1, Oct. 2022',
        //   desc: 'Benthic organisms is a crucial part in the marine ecosystem. Bryozoans are a group of marine benthos that have an indispensable role in maintaining marine biodiversity but are least known by the public. From the coastal water to 5500m in the deep sea, and from the equator to the polar seas, bryozoans are highly successful in colonizing different habitats. They grow and cover biotic or abiotic surfaces, and hence they possess severe biofouling problem to other sessile marine invertebrates as well as to the mankind. Up-to-date, little is known about their larval settlement mechanism, especially how their response to light facilitate the choice of settling substrate. This project aims at developing auto tracking algorithm for automated recording and analysis of bryozoans larval phototactic response. the project involved both wet lab and dry lab computational experiments to capture and analyze some unknown larval behavior.',
        //   src: require("../assets/img/ysa/v1i1/i1_cover.jpg"),
        // },
        // {
        //   id: 3,
        //   name:'Study of the Feeding behavior of Marine flatworms',
        //   title: 'Hermit Crab, 2023',
        //   desc: ` Research on hermit crabs can cover a wide range of topics, including their biology, behavior, ecology, evolution, and conservation. 
        //           Some research topic that students have investigated :
        //           What is the life cycle of hermit crabs, from egg to adult?
        //           How do hermit crabs select and use their shells?
        //           What is the ecological role of hermit crabs in their respective habitats?
        //           How do hermit crab populations respond to environmental changes, such as habitat loss or pollution?
        //           How have hermit crabs evolved over time, and how do they relate to other crustaceans?
        //           What are the threats facing hermit crabs, and how can we protect them?`,
        //   src: require("../assets/img/ysa/hermit-crab-research-2023.jpg"),
        // },        
        {
          id: 4,
          name:'3D microscopy of phytoplankton and 3D image reconstruction',
          title: 'Coral Research, 2025',
          desc: ` Coral reefs are among the most biodiverse ecosystems on the planet, and they are also highly vulnerable to human impacts such as overfishing, 
                  pollution, and climate change. By conducting research on coral, scientists can better understand the complex processes that underlie coral 
                  biology and ecosystem function, and develop strategies to conserve and restore these vital ecosystems.
                  What are the cellular and molecular mechanisms behind coral growth and reproduction?
                  How do environmental factors such as temperature, acidity, and pollution affect coral health and survival?
                  What are the ecological roles of coral reefs and how do they support marine biodiversity?
                  What are the genetic and evolutionary factors that shape the diversity of coral species?
                  How can we develop effective strategies to conserve and restore coral reefs in the face of climate change and other threats?
                `,
          src: require("../assets/img/ysa/coral-research-2023.jpg"),
        },
        // {
        //   id: 5,
        //   name:'Detection of objects with unstable morphology based on deep learning',
        //   title: 'Object Detection',
        //   desc: 'Most of the existing AI object tracking algorithms target objects with relative stable morphology, but they usually have a bad performance on tracking objects with changeable morphology. This limits their application scopes. This project will apply AI and image processing techniques to design algorithms specifically for tracking objects with changeable morphology, which can be very useful for plankton behavior observation, cell research in medicine, etc. This project involves knowledge and techniques of deep learning, image processing and data analysis.',
        //   src: require("../assets/img/ysa/i1_cover.jpg"),
        // },
        // {
        //   id: 6,
        //   name:'AI based object recognition and embedded edge computing',
        //   title: 'Edge Computing',
        //   desc: 'AI based object recognition and embedded edge computing. Modern machine vision technology based on AI has been successfully applied in many fields with high efficiency and performance. It has greatly reduced the cost of human labor and time. “AI for Science” has also been rated as one of the top 10 technology trends in 2022 by Ailibaba DAMO Academy. In addition, the application of AI is growing towards miniaturization, low cost, and low power consumption. In this project, AI technology will be applied to solve the widely demanded object recognition problem in plankton science research. Then the high-performance recognition algorithms will be deployed on the embedded platform to implement edge computing. We expect that this project will provide efficient and smart tools for plankton science research, promoting the development of “AI for Plankton Science”. This project involves knowledge and techniques of deep learning, embedded computer system, and edge computing.',
        //   src: require("../assets/img/ysa/i1_cover.jpg"),
        // },
        // {
        //   id: 7,
        //   name:'',
        //   title: 'Comming soon...',
        //   desc: '',
        //   src: require("../assets/img/ysa/i1_cover.jpg"),
        // },
        // {
        //   id: 8,
        //   name:'',
        //   title: 'Comming soon...',
        //   desc: '',
        //   src: require("../assets/img/ysa/i1_cover.jpg"),
        // },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  // max-width: 1920px;
  margin: 20px 5% auto;
  padding: 20px 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // margin-bottom: 100px;
}
.box {
  margin: 40px 10px ;
  position: relative;
  width: 270px;
  height: 360px;
  background: #000;
  box-shadow: 0 30px 30px rgba(0,0,0,0.5);
  // box-shadow: 0 5px 20px rgba(0,0,0,0.5);
}
.box .imgBx {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box .imgBx img{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: 0.5s;
}
.box:hover .imgBx img{
  opacity: 0;
}
.box .content {
  position: absolute;
  bottom: 20px;
  left: 10%;
  width: 80%;
  height: 60px;
  background: #0d1250;
  transition: 0.5s;
  overflow: hidden;

  padding: 15px;
  box-sizing: border-box;
}
.box:hover .content {
  width: 100%;
  height: 100%;
  bottom: 0;
  left: 0;
}
.box .content h3 {
  color: #fff;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
.box .content p {
  margin: 10px 0 0;
  padding: 0;
  opacity: 0;
  font-size: 15px;
  line-height: 1.2em;
  transition: 0.5s;
  text-align: left;

  // word-break: break-all;  是否可以中间打断一个词！！！
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 17;
  -webkit-box-orient: vertical;
}
.box:hover .content p {
  color: white;
  padding: 0;
  opacity: 1;
  transition-delay: 0.5s;
  
}
</style>